import React from "react";
import {Button} from "@mui/material";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';

export default function PriceBubble(props) {
    const features = props.features.map((feature, i) => <li key={i}>{feature}</li>);

    return (
        <div className={"priceBubble"}>
            <h4 className={"tierTitle"}>{props.name}</h4>
            <div className={"borderBox"}>
                <div className={"border"}></div>
            </div>
            <div className={"tierPrice"}>${props.price} / month</div>
            <span>
            <ul className={"tierFeatures"}>
                {features}
            </ul>
            <div className={"buttonHolder"}>
                {props.current && <div>My Current Plan</div>}
                {!props.current && <Button onClick={() => document.location.href="https://qrbom.com/QrBom/createCheckoutSession?priceId=" + props.priceId} fullWidth={true} className={"selectButton"}><strong>{props.userHasPlan && "Change Plan"}{!props.userHasPlan && "14-Day Free Trial"}</strong><FontAwesomeIcon className={"priceBubbleSubmitIcon"} icon={faArrowRight}/></Button>}
            </div>
        </span>
        </div>
    );
};
import React, { useState } from 'react';
import { Container, TextField, Button } from '@mui/material';
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert";

export default function ResetPassword() {
    const [email, setEmail] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        Ajax.request({
            url:"/QrBom/sendResetPasswordEmail",
            jsonData:{email:email},
            success:function() {
                swal({title:"Email Sent!", text:"A password reset email has been sent to " + email + ". If you cannot find the email, check your spam and junk folders.", icon:"success"});
            }
        });
    };

    return (
        <Container maxWidth="sm">
            <h1>Reset Password</h1>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email Address"
                    type="email"
                    variant="outlined"
                    margin="normal"
                    required={true}
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                >
                    Send Reset Password Email
                </Button>
            </form>
        </Container>
    );
}
import React, {useEffect, useState} from "react";
import {Box, Button, TextField} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Ajax from "../inc/js/Ajax";
import "../styles/Lookup.css";
import {makeStyles} from "@material-ui/core/styles";

export default function Lookup() {
    const [components, setComponents] = useState([]);

    const [form, setForm] = useState({
        company:"",
        supportPhone:"",
        supportEmail:"",
        website:"",
        assemblyName:"",
        assemblyDescription:"",
        assemblyLink:"",
        serialNumber:"",
        notes:"",
    });

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        const code = params.code;

        Ajax.request({
            url:"/QrBom/lookupQrCode",
            jsonData:{code:code},
            success:function(reply) {
                setComponents(reply.data.components);
                setForm(reply.data.assembly);
            }
        })
    }, []);

    function normalizeUrl(url) {
        if (!/^https?:\/\//i.test(url)) {
            url = 'https://' + url;
        }
        return url;
    }

    let initialColumnState = [
        {field:"id", headerName:"Component ID", hide:true},
        {field:"componentName", headerName:"Component", width: 200},
        {field:"componentDescription", headerName:"Description", width: 300},
        {field:"componentLink", headerName:"Link", width: 200, renderCell: (params) => (
                <a href={normalizeUrl(params.value)} target="_blank">
                    {params.value}
                </a>
        )},
        {field:"componentNotes", headerName:"Notes", width: 300}
    ];

    if(localStorage.getItem("lookupComponentColumns")) {
        let savedColumns = JSON.parse(localStorage.getItem("lookupComponentColumns"));
        for(let column in initialColumnState) {
            initialColumnState[column].hide = savedColumns[column].hide;
        }
    }

    const [columns, setColumns] = useState(initialColumnState)

    const handleColumnVisibilityChange = (field, checked) => {
        const updatedColumns = columns.map((col) => {
                return col.field === field.field ? {...col, hide: !field.isVisible} : col
            }
        );

        setColumns(updatedColumns);

        localStorage.setItem("lookupComponentColumns", JSON.stringify(updatedColumns));
    };

    const useStyles = makeStyles((theme) => ({
        textField: {
            width: '100%', // Full width on mobile
            [theme.breakpoints.up('md')]: {
                width: 300, // 300px on desktop
            },
        },
        grid: {
            width: '100%',
            height:500,
            display:"flex",
            flexDirection:"column"
        },
    }));

    const classes = useStyles();

    return (
        <div className={"lookup"}>
            <Box sx={{p:"1rem", display:"flex", flexDirection:"column"}}>
                <TextField
                    sx={{mb:".5rem"}}
                    id="company"
                    className={classes.textField}
                    label="Manufacturer"
                    variant="standard"
                    value={form.company}
                ></TextField>
                <TextField
                    sx={{mb:".5rem", display:(!form.supportPhone ? "none" : "flex")}}
                    id="supportPhone"
                    className={classes.textField}
                    label="Support Phone"
                    variant="standard"
                    value={form.supportPhone}
                ></TextField>
                <TextField
                    sx={{mb:".5rem", display:(!form.supportEmail ? "none" : "flex")}}
                    id="supportEmail"
                    className={classes.textField}
                    label="Support Email"
                    variant="standard"
                    value={form.supportEmail}
                ></TextField>
                <TextField
                    sx={{mb:".5rem", display:(!form.website ? "none" : "flex")}}
                    id="website"
                    className={classes.textField}
                    label="Manufacturer Website"
                    variant="standard"
                    value={form.website}
                ></TextField>
                <TextField
                    sx={{mb:".5rem"}}
                    id="assemblyName"
                    className={classes.textField}
                    label="Assembly"
                    variant="standard"
                    value={form.assemblyName}

                ></TextField>
                <TextField
                    sx={{mb:".5rem", display:(!form.assemblyDescription ? "none" : "flex")}}
                    id="assemblyDescription"
                    className={classes.textField}
                    label="Description"
                    variant="standard"
                    value={form.assemblyDescription}

                ></TextField>
                <TextField
                    sx={{mb:".5rem"}}
                    id="serialNumber"
                    className={classes.textField}
                    label="Serial #"
                    variant="standard"
                    value={form.serialNumber}

                ></TextField>
                <TextField
                    sx={{mb:".5rem", display:(!form.assemblyLink ? "none" : "flex")}}
                    id="assemblyLink"
                    className={classes.textField}
                    label="Link"
                    variant="standard"
                    value={form.assemblyLink}

                ></TextField>
                <TextField
                    sx={{mb:".5rem", display:(!form.notes ? "none" : "flex")}}
                    id="notes"
                    className={classes.textField}
                    label="Notes"
                    variant="standard"
                    value={form.notes}

                ></TextField>
            </Box>
            <Box className={classes.grid} sx={{mt:"1rem"}}>
                <Box className={"gridTitle"}>Components</Box>
                <DataGrid columns={columns} rows={components}
                      onColumnVisibilityChange={handleColumnVisibilityChange}
                />
            </Box>
        </div>
    );
}
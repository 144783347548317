import React from "react";
import "../styles/Login.css";
import {Button, Link, TextField} from "@mui/material";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLockOpen, faPlus} from '@fortawesome/free-solid-svg-icons';
import CreateAccount from "./CreateAccount";
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert";

export default function Login(props) {
    function login() {
        const form = {
            username:document.getElementById("username").value,
            password:document.getElementById("password").value,
        };

        Ajax.request({
            url:"/QrBom/authenticate",
            jsonData:form,
            success:function(reply) {
                if(reply.login) {
                    document.location.href = "/";
                } else {
                    swal({title:"Oops!", text:"Incorrect username or password.", icon:"error", timer: 3000});
                }
                document.getElementById("username").value = "";
                document.getElementById("password").value = "";
                document.getElementById("username").focus();
            }
        });
    }

    function loginMicrosoft() {
        document.location.href = "https://qrbom.local/AuthAzure/login"
    }

    function createAccount() {
        document.location.href = "/createAccount"
    }

    function handleKeyDown(event) {
        if(event.key === "Enter") {
            login();
        }
    }

    return (
        <div className={"login"}>
            <h1>Login</h1>

            <TextField
                id="username"
                className="textField"
                label="Email"
                variant="standard"
                type={"email"}
                required={true}
                fullWidth={window.innerWidth < 760}
            ></TextField>
            <TextField
                id="password"
                className="textField"
                label="Password"
                variant="standard"
                type="password"
                required={true}
                fullWidth={window.innerWidth < 760}
                onKeyDown={handleKeyDown}
            ></TextField>
            <Button onClick={login}><FontAwesomeIcon className={"login-icon"} id={"loginButton"} icon={faLockOpen}/>Login</Button>
            <Link href={"/resetPassword"}>Forgot password?</Link>
            {/*<Button onClick={loginMicrosoft}><img className={"login-icon"} src={require("../images/microsoft.png")} width={16} height={16}  alt={"Microsoft"}/>Login With Microsoft</Button>*/}
            <Button onClick={createAccount}><FontAwesomeIcon className={"login-icon"} id={"createAccountButton"} icon={faPlus}/>Create Account</Button>
        </div>
    );
}
import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";

export default function UserGuide(props) {
    const [headings, setHeadings] = useState([]);

    useEffect(() => {
        const headingElements = document.querySelectorAll('h2');
        const headings = Array.from(headingElements).map((heading) => ({
            id: heading.id,
            text: heading.textContent,
        }));
        setHeadings(headings);
    }, []);


    return (
        <Box sx={{margin:"2rem"}}>
            <h1>User Guide</h1>
            <h3>Table of Contents</h3>
            <ul>
                {headings.map((heading) => (
                    <li key={heading.id}>
                        <a href={`#${heading.id}`}>{heading.text}</a>
                    </li>
                ))}
            </ul>
            <h2 id={"gettingStarted"}>1. Getting Started</h2>
            <ul>
                <li><a target={"_blank"} rel="noreferrer" href={"/createAccount"}>Create an account</a>.</li>
                <li><a target={"_blank"} rel="noreferrer" href={"/login"}>Log in</a> to your account.</li>
                <li>Select a <a target={"_blank"} rel="noreferrer" href={"/pricing"}>plan</a> to access the dashboard. All plans have a 14-day trial that can be canceled at any time.</li>
                <li>Familiarize yourself with the {props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard"}>dashboard</a>}{!props.userInformation && "dashboard"}, which consists of four tabs:
                    &nbsp;{props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard?tab=0"}>Qr Code</a>}{!props.userInformation && "QR Code"},
                    &nbsp;{props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard?tab=1"}>Assemblies</a>}{!props.userInformation && "Assemblies"},
                    &nbsp;{props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard?tab=2"}>Components</a>}{!props.userInformation && "Components"},
                    &nbsp;and {props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard?tab=3"}>Past QR Codes</a>}{!props.userInformation && "Past QR Codes"}.</li>
            </ul>
            <h2 id={"managingAssemblies"}>2. Managing Assemblies</h2>
            <ul>
                <li>In the {props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard?tab=1"}>"Assemblies"</a>}{!props.userInformation && "\"Assemblies\""} tab, you can create, update, or delete assemblies.</li>
                <li>To create a new assembly, use the form in the middle of the tab. Press the "New" button in the toolbar and fill out the fields. Press "Save" to save the new assembly.</li>
                <li>To update an existing assembly, click on a row in the assembly list. The fields will fill the form below. You can then change the fields and press the "Save" button in the toolbar to save the assembly.</li>
                <li>To delete an existing assembly, click on a row in the assembly list. Press the "Delete" button in the toolbar and confirm the prompt.</li>
                <li>Below the form, you can view and manage the components associated with a specific assembly.</li>
                <li>Click into the "Add Component" field and select the components that you wish to add to the assembly. You can begin typing when in the field to search for a component.</li>
                <li>To unlink a component from the selected assembly, press the "Delete" button under the "Action" column for the given component in the "Components" list.</li>
            </ul>
            <h2 id={"managingComponents"}>3. Managing Components</h2>
            <ul>
                <li>Navigate to the {props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard?tab=2"}>"Components"</a>}{!props.userInformation && "\"Components\""} tab to manage your components.</li>
                <li>To create a new component, use the form in the middle of the tab. Press the "New" button in the toolbar and fill out the fields. Press "Save" to save the new component.</li>
                <li>To update an existing component, click on a row in the component list. The fields will fill the form below. You can then change the fields and press the "Save" button in the toolbar to save the component.</li>
                <li>To delete an existing component, click on a row in the component list. Press the "Delete" button in the toolbar and confirm the prompt.</li>
                <li>Deleting a component will remove it from any assemblies it is linked to, however it will remain linked to any QR codes generated with it.</li>
            </ul>
            <h2 id={"linkingComponentsToAssemblies"}>4. Linking Components to Assemblies</h2>
            <ul>
                <li>Before generating QR codes, you need to link components to assemblies.</li>
                <li>In the {props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard?tab=1"}>"Assemblies"</a>}{!props.userInformation && "\"Assemblies\""} tab, select an assembly from the list.</li>
                <li>Below the form, you can view and manage the components associated with a specific assembly.</li>
                <li>Click into the "Add Component" field and select the components that you wish to add to the assembly. You can begin typing when in the field to search for a component.</li>
                <li>To unlink a component from the selected assembly, press the "Delete" button under the "Action" column for the given component in the "Components" list.</li>
            </ul>
            <h2 id={"generatingQrCodes"}>5. Generating QR Codes</h2>
            <ul>
                <li>Go to the {props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard?tab=0"}>"Qr Code"</a>}{!props.userInformation && "\"QR Code\""} tab to generate QR codes for your assemblies.</li>
                <li>Enter the serial number for the unit you are creating the QR code for. This can be any letter/number combination and is only used for tracking specific QR codes.</li>
                <li>Select an assembly from the dropdown list.</li>
                <li>A list will display the linked components for the selected assembly.</li>
                <li>Click the "Generate QR Code" button to create a QR code and a corresponding link.</li>
                <li>Specify the inches and optional QR code Label fields.</li>
                <li>Click the print button to print the QR code, including the specified inches and label if provided.</li>
                <li>The inches and QR code Label fields are saved to the browser cache and stay after refreshing the page.</li>
            </ul>
            <h2 id={"viewingPastQrCodeInformation"}>6. Viewing Past QR Code Information</h2>
            <ul>
                <li>In the {props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard?tab=3"}>"Past QR Codes"</a>}{!props.userInformation && "\"Past QR Codes\""} tab, you can view past QR codes and their associated scans.</li>
                <li>The first column shows the most recent scan.</li>
                <li>Use the form below to update information about a specific QR code. To update, select a QR code from the list, and the data will populate the fields in the form.</li>
                <li>You can now begin changing the fields and press the "Save" button in the toolbar to save the QR code information.</li>
                <li>You can also delete a QR code. This will cause scanning the selected QR code to no longer work.</li>
                <li>To delete, select a QR code from the list and press the "Delete" button in the toolbar. Confirm the deletion prompt and the QR code will be removed from the list.</li>
                <li>When someone scans the QR code, they will see the updated information.</li>
                <li>Below the form, there is a list that displays all the scans for the selected QR code.</li>
            </ul>
            <h2 id={"importingAndExportingData"}>7. Importing and Exporting Data</h2>
            <ul>
                <li>If you have the <a target={"_blank"} rel="noreferrer" href={"/pricing"}>standard or enterprise plan</a>, you can import assemblies, components, and assembly/component links from CSV files.</li>
                <li>In the {props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard?tab=1"}>"Assemblies"</a>}{!props.userInformation && "\"Assemblies\""} tab, use the import button in the toolbar of the "Assemblies" list to import assemblies from a CSV file.</li>
                <li>Make sure your csv columns match the example shown exactly. This will be case-sensitive. The columns should be:
                    <ol>
                        <li>Assembly</li>
                        <li>Description</li>
                        <li>External ID</li>
                        <li>Link</li>
                        <li>Notes</li>
                    </ol>
                </li>
                <li>In the {props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard?tab=1"}>"Assemblies"</a>}{!props.userInformation && "\"Assemblies\""} tab, use the import button in the toolbar of the "Components" list to link components to assemblies from a CSV file.</li>
                <li>You do not have to select an assembly before doing this, and you can import links for multiple assemblies in the same import.</li>
                <li>Before importing, make sure all of the assemblies and components in your csv have already been imported into the system.</li>
                <li>Make sure your csv columns match the example shown exactly. This will be case-sensitive. The columns should be:
                    <ol>
                        <li>Assembly</li>
                        <li>Component</li>
                    </ol>
                </li>
                <li>In the {props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard?tab=2"}>"Components"</a>}{!props.userInformation && "\"Components\""} tab, use the import button  in the toolbar of the "Components" list to import components from a CSV file.</li>
                <li>Make sure your csv columns match the example shown exactly. This will be case-sensitive. The columns should be:
                    <ol>
                        <li>Component</li>
                        <li>Description</li>
                        <li>External ID</li>
                        <li>Link</li>
                        <li>Notes</li>
                    </ol>
                </li>
                <li>Data can be exported from lists using the "Export" button shown in the toolbar of the list.</li>
            </ul>
            <h2 id={"updatingYourInformation"}>8. Updating your information</h2>
            <ul>
                <li>To update your information, go to {props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/profile"}>"My Account"</a>}{!props.userInformation && "\"My Account\""}.</li>
                <li>You can edit your information here and press "Update Profile" at the bottom to save your changes.</li>
                <li>Press "Change Plan" at the bottom to go to the <a target={"_blank"} rel="noreferrer" href={"/pricing"}>pricing</a> page and pick a new plan.</li>
                <li>Press "Cancel Plan" at the bottom to cancel your existing plan. Doing so will make your QR codes no longer show information when scanned.</li>
            </ul>
            <h2 id={"changingYourPlan"}>9. Changing your plan</h2>
            <ul>
                <li>To change your plan, go to <a target={"_blank"} rel="noreferrer" href={"/pricing"}>Pricing</a>.</li>
                <li>If you are currently subscribed to a plan, you will see "My Current Plan" below your selected plan.</li>
                <li>Press "Change Plan" to select a new plan. You cannot downgrade if you have more assemblies or components than the new plan allows.</li>
            </ul>
            <h2 id={"cancelingYourPlan"}>10. Canceling your plan</h2>
            <ul>
                <li>To cancel your plan, go to {props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/profile"}>"My Account"</a>}{!props.userInformation && "\"My Account\""}.</li>
                <li>Press "Cancel Plan" at the bottom to cancel your existing plan. Doing so will make your QR codes no longer show information when scanned.</li>
            </ul>
            <h2 id={"troubleShooting"}>11. Troubleshooting</h2>
            <ul>
                <li>If you encounter any issues while using the app, try refreshing the page or logging out and logging back in.</li>
                <li>Ensure you have a stable internet connection for optimal performance.</li>
                <li><a target={"_blank"} rel="noreferrer" href={"https://its.uiowa.edu/support/article/719"}>Clear your browser cache and cookies</a> if you experience any unexpected behavior.</li>
                <li>If the issue persists, <a target={"_blank"} rel="noreferrer" href={"/contact"}>contact the QR BOM support team</a> for further assistance.</li>
            </ul>
            <h2 id={"faq"}>12. Frequently Asked Questions</h2>
            <ul>
                <strong>Q: Can I use QR BOM offline?</strong><br/>
                A: QR BOM is a web-based application and requires an internet connection to function. Offline usage is not supported.
            </ul>
            <ul>
                <strong>Q: Can I change the information displayed on the QR code scan screen after printing the QR code?</strong><br/>
                A: Yes, you can update the information associated with a QR code in the {props.userInformation && <a target={"_blank"} rel="noreferrer" href={"/dashboard?tab=3"}>"Past QR Codes"</a>}{!props.userInformation && "\"Past QR Codes\""} tab. Any changes made will be reflected when the QR code is scanned.
            </ul>
            <ul>
                <strong>Q: How can I import assemblies and components from a CSV file?</strong><br/>
                A: If you have the standard or enterprise plan, you can use the import buttons in the "Assemblies" and "Components" tabs to import data from CSV files. Make sure the CSV file format follows the specified guidelines.
            </ul>
            <ul>
                <strong>Q: Are there any limitations on the number of assemblies and components I can manage?</strong><br/>
                A: The amount of assemblies and components you can manage is based on the plan you have subscribed to. These are listed on the <a target={"_blank"} rel="noreferrer" href={"/pricing"}>pricing</a> screen.
            </ul>
            <ul>
                <strong>Q: Are there any limitations on the number of QR codes I can create?</strong><br/>
                A: The amount of QR codes you can create is based on the plan you have subscribed to. There is a maximum amount that you can generate per month, which resets on the 1st of the month. These are listed on the <a target={"_blank"} rel="noreferrer" href={"/pricing"}>pricing</a> screen.
            </ul>
            <ul>
                <strong>Q: Can I share the QR codes with others?</strong><br/>
                A: Yes, you can share the QR codes generated by the app by providing the corresponding link. When someone scans the QR code, they will be directed to the associated information.
            </ul>
            <ul>
                <strong>Q: How secure is my data in QR BOM?</strong><br/>
                A: QR BOM takes measures to ensure the security of your data. However, it is recommended to use strong and unique passwords and follow best practices to protect your account.
            </ul>
            <ul>
                <strong>Q: Is there a mobile app version of QR BOM?</strong><br/>
                A: QR BOM is currently a web-based application and does not have a dedicated mobile app. However, you can access the app from a mobile device's web browser.
            </ul>
        </Box>
    );
};
import React from "react";
import "../styles/Home.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCubesStacked, faQrcode, faMobilePhone} from "@fortawesome/free-solid-svg-icons";

export default function Home(props) {
    return (
        <div>
            <main>
                <section className="hero">
                    <h2>Effortlessly Manage Your Assemblies and Components</h2>
                    <p>With QRBOM, you can easily create and print QR codes for your units with detailed information about their components. End users and Service Agents can scan the code with a smartphone to access detailed information about each component and the unit as a whole.</p>
                    {props.userInformation && <a href={"/pricing"}><button>Get started with a 14-day free trial</button></a>}
                    {!props.userInformation && <a href={"/createAccount"}><button>Get started with a 14-day free trial</button></a>}
                </section>
                <section className="features">
                    <div className="feature">
                        <FontAwesomeIcon icon={faCubesStacked} size={"lg"} />
                        <h3>Manage Your Assemblies</h3>
                        <p>Organize your assemblies and components in one place. View detailed information about each assembly, including the parts list and manufacturing instructions.</p>
                    </div>
                    <div className="feature">
                        <FontAwesomeIcon icon={faQrcode} size={"lg"} />
                        <h3>Print QR Codes</h3>
                        <p>Create and print QR codes for your units and components. Attach them to the product for easy access to information about the unit and its components.</p>
                    </div>
                    <div className="feature">
                        <FontAwesomeIcon icon={faMobilePhone} size={"lg"} />
                        <h3>Scan and Access</h3>
                        <p>Scan the QR code with a smartphone to access detailed information about each component and the unit as a whole. Get all the information you need in one place.</p>
                    </div>
                </section>
                <section className="call-to-action">
                    <h2>Ready to Get started?</h2>
                    <p>Create your account today and start managing your assemblies and components with ease.</p>
                    {props.userInformation && <a href={"/pricing"}><button>Get started with a 14-day free trial</button></a>}
                    {!props.userInformation && <a href={"/createAccount"}><button>Get Started with a 14-day free trial</button></a>}
                </section>
            </main>
            <footer className="footer">
                <p>&copy; 2023 QRBOM.com. All Rights Reserved. <a href="/privacyPolicy">Privacy Policy</a></p>
            </footer>
        </div>
    );
}
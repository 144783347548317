import swal from 'sweetalert';

class Ajax {
    request(configParams = {}) {
        const defaults = {
            method:"POST",
            params:{},
            jsonData:{},
            body:"",
            headers:{},
            success:function() {},
            failure:function(error) {
                swal({title:"Oops!", text:error.toString(), icon:"error"});
            }
        };

        const config = {...defaults, ...configParams};

        if(!config.hasOwnProperty("url")) {
            console.error("Url not defined!");
        }

        if(Object.keys(config.params).length > 0) {
            config.headers['Content-Type'] = ' application/x-www-form-urlencoded';
            let formBody = [];
            for (let property in config.params) {
                var encodedKey = encodeURIComponent(property);
                var encodedValue = encodeURIComponent(config.params[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
            config.body = formBody;
        }

        if(Object.keys(config.jsonData).length > 0) {
            config.headers['Content-Type'] = 'application/json';
            config.body = JSON.stringify(config.jsonData);
        }

        if(Object.keys(config.params).length > 0 && Object.keys(config.jsonData).length > 0) {
            console.error("Cannot define params and jsonData!");
        }

        let fetchConfig = {
            method: config.method,
            headers: config.headers
        };

        if(Object.keys(config.body).length > 0) {
            fetchConfig.body = config.body;
        }
        fetchConfig.headers = {"X-Requested-With":"XMLHttpRequest"}

        const currentMaskDisplay = document.getElementById("loading").style.display;
        let hideMaskOnFinish = false;
        if(currentMaskDisplay !== "flex") {
            //document.getElementById("loading").style.display = "flex";
            hideMaskOnFinish = true;
        }

        fetch(config.url, fetchConfig)
        .then((response) => {
            if(response.ok) {
                return response.text()
            } else {
                return response.text().then(error => {
                    try {
                        error = JSON.parse(error);
                        if(error.hasOwnProperty("error")) {
                            return Promise.reject(error.error);
                        }
                    } catch(e) {
                        return Promise.reject(error);
                    }
                });
            }
        })
        .then((reply) => {
            try {
                reply = JSON.parse(reply.toString());
                if(reply.hasOwnProperty("success")) {
                    if(reply.success) {
                        if(hideMaskOnFinish) {
                            document.getElementById("loading").style.display = "none";
                        }
                        config.success(reply);
                    } else {
                        if(hideMaskOnFinish) {
                            document.getElementById("loading").style.display = "none";
                        }
                        config.failure(reply.error);
                    }
                }
            } catch(err) {
                if(hideMaskOnFinish) {
                    document.getElementById("loading").style.display = "none";
                }
                config.failure(reply);
            }
        })
        .catch((err) => {
            if(hideMaskOnFinish) {
                document.getElementById("loading").style.display = "none";
            }
            config.failure(err);
        });
    }
}

const ajax = new Ajax();
export default ajax;
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {Autocomplete, Button, Link, TextField} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import Assemblies from "./Assemblies";
import {useEffect, useState} from "react";
import Ajax from "../inc/js/Ajax";
import Components from "./Components";
import PastQRCodes from "./PastQRCodes";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

let assemblyId = null;

export default function Dashboard(props) {
    const [value, setValue] = React.useState(0);

    const [comboData, setComboData] = useState({assemblies:[], components:[]});

    const [components, setComponents] = useState([]);

    const [qrCodeLink, setQrCodeLink] = useState("");

    let storedQrCodeLabel = localStorage.getItem('qrCodeLabel')

    if(storedQrCodeLabel === null) {
        storedQrCodeLabel = "Scan For Information"
    }

    const [inches, setInches] = useState(localStorage.getItem('inches') || 2);

    const [qrCodeLabel, setQrCodeLabel] = useState(storedQrCodeLabel);

    useEffect(() => {
        readComboData();
    }, []);

    function readComboData() {
        Ajax.request({
            url:"/QrBom/readDashboardComboData",
            success:function(reply) {
                setComboData({
                    assemblies:reply.data.assemblies
                });
            }
        });
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setQrCodeLink("");
        if(newValue === 0) {
            readComboData();
        }
    };

    function readComponentsForAssembly(event, record) {
        if(record === null) {
            assemblyId = null;
            setComponents([]);
            return;
        }

        Ajax.request({
            url:"/QrBom/readComponentsByAssembly",
            jsonData:{assemblyId:record.id},
            success:function(reply) {
                assemblyId = record.id;
                setComponents(reply.data);
            }
        });
    }

    const componentColumns = [
        {
            field: "component",
            headerName: "Component",
            width:200
        },
        {
            field: "description",
            headerName: "Description",
            width:300
        },
        {field:"externalId", headerName:"External ID", width: 200, hide:true},
        {field:"link", headerName:"Link", width: 200},
        {field:"notes", headerName:"Notes", width: 300}
    ];

    function generateQrCode() {
        Ajax.request({
            url:"/QrBom/generateQrCode",
            jsonData:{
                serial:document.getElementById("qrCodeSerial").value,
                assemblyId:assemblyId,
                components:components
            },
            success:function(reply) {
                setQrCodeLink(reply.data);
            }
        });
    }

    if(qrCodeLink) {
        const QRCode = require('qrcode');
        const canvas = document.getElementById('qrCodeCanvas');
        QRCode.toCanvas(canvas, qrCodeLink, function (error) {

        });
    }

    function printQrCode() {
        var canvas = document.getElementById("qrCodeCanvas");
        var img = new Image();
        img.src = canvas.toDataURL();

        img.onload = function() {
            var printWindow = window.open('', '', 'height=400,width=400');
            printWindow.document.write('<html><head><style>.qrCodeLabelSpan {font-family: Poppins, sans-serif; text-align: center} @page { size: ' + inches + 'in; margin: 0; }</style><title>' + document.getElementById("qrCodeSerial").value + '</title></head><body>');
            if(qrCodeLabel) {
                printWindow.document.write('<div class="qrCodeLabelSpan"><span class="qrCodeLabelSpan">' + qrCodeLabel + '</span><img src="' + img.src + '" style="width:80%;"/></div>');
            } else {
                printWindow.document.write('<img src="' + img.src + '" style="width:100%;"/>');
            }
            printWindow.document.write('</body></html>');
            printWindow.print();
            printWindow.document.close();
            window.onafterprint = function() {
                printWindow.close();
            };
        }
    }

    const handleInchesChange = (event) => {
        const value = event.target.value;
        setInches(value);
        localStorage.setItem('inches', value);
        updatePrintSize(value);
    };

    const handleQrCodeLabelChange = (event) => {
        const value = event.target.value;
        setQrCodeLabel(value);
        localStorage.setItem('qrCodeLabel', value);
        updatePrintSize(value);
    };

    useEffect(() => {
        updatePrintSize(inches);
    }, []);

    const updatePrintSize = (inches) => {
        const style = document.createElement('style');
        style.innerHTML = `
      @media print {
        @page {
          size: ${inches}in;
        }
      }
    `;
        document.head.appendChild(style);
    };

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if(params.tab) {
            setValue(parseInt(params.tab));
        }
    }, []);

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex'}}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider', minWidth:"10rem"}}
            >
                <Tab label="QR Code" {...a11yProps(0)} />
                <Tab label="Assemblies" {...a11yProps(1)} />
                <Tab label="Components" {...a11yProps(2)} />
                <Tab label="Past QR Codes" {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Box sx={{display:"flex", flexDirection:"column"}}>

                    <TextField
                        id="qrCodeSerial"
                        className="textField"
                        label="Serial"
                        variant="standard"
                        sx={{ mt:"2rem"}}
                    />
                    <Autocomplete
                        disablePortal
                        id="qrCodeAssembly"
                        options={comboData.assemblies}
                        sx={{ mt:"2rem", width: "18.75rem" }}
                        autoHighlight={true}
                        onChange={readComponentsForAssembly}
                        renderInput={(params) => <TextField {...params} label="Assembly" />}
                    />
                    <Box className={"gridContainer"} height={"20rem"} width={"75rem"} sx={{mt:"2rem"}}>
                        <Box className={"gridTitle"}>Components</Box>
                        <DataGrid columns = {componentColumns} rows={components}>

                        </DataGrid>
                    </Box>
                    <Button sx={{mt: "1rem", color:"black", backgroundColor:"lightskyblue"}} onClick={generateQrCode}>Generate QR Code</Button>
                    <Box id={"qrCodeContainer"} sx={{display:qrCodeLink === "" ? "none" : "flex", alignItems:"center", mt:"1rem"}}>
                        <canvas id="qrCodeCanvas"></canvas>
                        <Link sx = {{ml:"2rem"}} target="_blank" href={qrCodeLink}>{qrCodeLink}</Link>
                    </Box>
                    <TextField
                        id="size"
                        className="textField"
                        label="Size (Inches)"
                        variant="standard"
                        type={"number"}
                        value={inches}
                        InputProps={{
                            inputProps: {
                                min: 1,
                                max: 12,
                            },
                        }}
                        onChange={handleInchesChange}
                        sx={{ mt:"2rem", display:qrCodeLink === "" ? "none" : "flex"}}
                    />
                    <TextField
                        id="qrCodeLabel"
                        className="textField"
                        label="QR Code Label"
                        variant="standard"
                        value={qrCodeLabel}
                        onChange={handleQrCodeLabelChange}
                        sx={{ mt:"2rem", display:qrCodeLink === "" ? "none" : "flex"}}
                    />
                    <Button sx={{display:qrCodeLink === "" ? "none" : "flex", mt:"1rem", color:"black", backgroundColor:"lightskyblue"}} onClick={printQrCode}>Print</Button>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Assemblies userInformation = {props.userInformation}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Components userInformation = {props.userInformation}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <PastQRCodes />
            </TabPanel>
        </Box>
    );
}
import React from 'react';
import "../styles/Error404.css";
function Error404() {
    return (
        <div className="error-page">
            <h1>404 Error</h1>
            <p>Sorry, the page you were looking for does not exist.</p>
        </div>
    );
}

export default Error404;
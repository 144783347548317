import React, {useEffect, useState} from "react";
import "../styles/Profile.css";
import {Box, Button, Link, TextField} from "@mui/material";
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert";
import InputMask from "react-input-mask";

export default function Profile(props) {
    function updateProfile() {
        Ajax.request({
            url:"/QrBom/updateProfile",
            jsonData:form,
            success:function(reply) {
                swal({title:"Success!", text:"Profile Updated", icon:"success", timer: 3000});
            }
        });
    }

    const [form, setForm] = useState({
        email:"",
        firstName:"",
        lastName:"",
        company:"",
        supportPhone:"",
        supportEmail:"",
        website:"",
        plan:""
    });

    function handleChange(event) {
        let change = {};
        change[event.target.id] = event.target.value;

        setForm({...form, ...change});
    }

    function cancelPlan() {
        swal({
            title: 'Are you sure?',
            text: 'Cancel Subscription>',
            icon: "warning",
            buttons: [
                'No',
                'Yes'
            ],
        }).then((isConfirm) => {
            if(isConfirm) {
                Ajax.request({
                    url:"/QrBom/cancelSubscription",
                    success:function() {
                        window.location.reload();
                    }
                })
            }
        });
    }

    useEffect(() => {
        if(props.userInformation) {
            setForm(props.userInformation);
        }
    }, [props]);

    return (
        <div className={"profile"}>
            <TextField
                id="email"
                className="textField"
                label="Email"
                variant="standard"
                value={form.email}
                onChange={handleChange}
                sx={{mb:"1rem"}}
                fullWidth={window.innerWidth < 760}>
            </TextField>
            <TextField
                id="firstName"
                className="textField"
                label="First Name"
                variant="standard"
                value={form.firstName}
                onChange={handleChange}
                sx={{mb:"1rem"}}
                fullWidth={window.innerWidth < 760}>
            </TextField>
            <TextField
                id="lastName"
                className="textField"
                label="Last Name"
                variant="standard"
                value={form.lastName}
                onChange={handleChange}
                sx={{mb:"1rem"}}
                fullWidth={window.innerWidth < 760}>
            </TextField>
            <TextField
                id="company"
                className="textField"
                label="Company Name"
                variant="standard"
                value={form.company}
                onChange={handleChange}
                sx={{mb:"1rem"}}
                fullWidth={window.innerWidth < 760}>
            </TextField>
            <InputMask
                mask="(999) 999-9999"
                onChange={handleChange}
                value={form.supportPhone}
            >
                {(inputProps) => (
                    <TextField
                        {...inputProps}
                        id="supportPhone"
                        className="textField"
                        label="Support Phone"
                        variant="standard"
                        sx={{mb:"1rem"}}
                        fullWidth={window.innerWidth < 760}
                    />
                )}
            </InputMask>
            <TextField
                id="supportEmail"
                className="textField"
                label="Support Email"
                variant="standard"
                value={form.supportEmail}
                onChange={handleChange}
                sx={{mb:"1rem"}}
                fullWidth={window.innerWidth < 760}>
            </TextField>
            <TextField
                id="website"
                className="textField"
                label="Website"
                variant="standard"
                value={form.website}
                onChange={handleChange}
                sx={{mb:"1rem"}}
                fullWidth={window.innerWidth < 760}>
            </TextField>
            <TextField
                id="plan"
                className="textField"
                label="My Plan"
                variant="standard"
                value={form.plan}
                onChange={handleChange}
                sx={{mb:"1rem"}}
                fullWidth={window.innerWidth < 760}>
            </TextField>
            {form.plan === "None" && <Link sx={{mb:"1rem"}} href="/pricing">Select Plan</Link>}
            {form.plan !== "None" && <Link sx={{mb:"1rem"}} href="/pricing">Change Plan</Link>}
            {form.plan !== "None" && <Button sx={{mb:"1rem"}} onClick={cancelPlan}>Cancel Plan</Button>}
            <Button onClick={updateProfile}>Update Profile</Button>
        </div>
    );
}
import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
        padding:'2rem'
    },
    message: {
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

export default function VerifyEmail() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h5" component="h1" align="center" className={classes.message}>
                Thank you for signing up! Please check your email to verify your account. If you cannot find the email, check your spam and junk folders.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/login"
                className={classes.button}
            >
                Go to Login
            </Button>
        </div>
    );
}
import React, {useState} from "react";
import "../styles/Header.css";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LoginIcon from '@mui/icons-material/Login';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';
import Ajax from "../inc/js/Ajax";
import {
    AppBar,
    Box,
    Divider,
    Drawer,
    IconButton, Link,
    ListItemButton,
    ListItemIcon,
    ListItemText, Menu, MenuItem,
    Toolbar
} from "@mui/material";

export default function Header(props) {
    function logout() {
        Ajax.request({
            url:"/Logout/",
            success:function() {
                document.location.href = "/";
            }
        });
    }

    const [state, setState] = useState({
        openSideMenu:false,
        openMyAccountMenu:false,
        myAccountMenuAnchor:null
    });

    function toggleDrawer(event, open) {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, ...{openSideMenu:open}})
    }

    function toggleMyAccountMenu(event, open) {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, ...{openMyAccountMenu:open, myAccountMenuAnchor:event.currentTarget}})
    }

    return (
        <AppBar position={"static"}>
            <Toolbar
                sx={{
                    flexDirection: "row",
                    backgroundColor: "slyblue",
                    justifyContent: "space-between"
                }}
            >
                <MenuItem component={Link} href="/">
                    <img className={"navbar-logo"} src={require("../images/logo.png")} alt={"logo"}></img>
                    <span className={"navbar-title"}>QR BOM</span>
                </MenuItem>
                <Box>
                    <Box
                        sx = {{
                            display: { xs: "none", sm:"flex"}
                        }}
                    >
                        {props.userInformation && props.userInformation.plan !== "None" && <MenuItem component={Link} href="/dashboard">
                            <DashboardIcon sx={{color: "primary.secondary"}}/>
                            <span className={"navbar-link"}>Dashboard</span>
                        </MenuItem>}

                        <MenuItem component={Link} href="/userGuide">
                            <InfoIcon sx={{color: "primary.secondary"}}/>
                            <span className={"navbar-link"}>User Guide</span>
                        </MenuItem>

                        <MenuItem component={Link} href="/pricing">
                            <AttachMoneyIcon sx={{color: "primary.secondary"}}/>
                            <span className={"navbar-link"}>Pricing</span>
                        </MenuItem>

                        <MenuItem component={Link} href="/contact">
                            <EmailIcon sx={{color: "primary.secondary"}}/>
                            <span className={"navbar-link"}>Contact Us</span>
                        </MenuItem>

                        <MenuItem
                            onClick={(event) => {toggleMyAccountMenu(event, true)}}
                        >
                            <AccountBoxIcon sx={{color: "primary.secondary"}}/>
                            <span className={"navbar-link"}>My Account</span>
                        </MenuItem>
                        <Menu
                            anchorEl={state.myAccountMenuAnchor}
                            open={state.openMyAccountMenu}
                            onClose={(event) => {toggleMyAccountMenu(event, false)}}
                        >
                            {!props.userInformation &&
                                <MenuItem component={Link} href="/login">
                                    <LoginIcon sx={{color: "primary.main"}}/>
                                    <span className={"navbar-link-black"}>Login</span>
                                </MenuItem>
                            }

                            {!props.userInformation &&
                                <MenuItem component={Link} href="/createAccount">
                                    <AppRegistrationIcon sx={{color: "primary.main"}}/>
                                    <span className={"navbar-link-black"}>Create Account</span>
                                </MenuItem>
                            }

                            {props.userInformation &&
                                <MenuItem component={Link} href="/profile">
                                    <AccountBoxIcon sx={{color: "primary.main"}}/>
                                    <span className={"navbar-link-black"}>My Account</span>
                                </MenuItem>
                            }

                            {props.userInformation &&
                                <MenuItem component={Link} href="/billing">
                                    <AttachMoneyIcon sx={{color: "primary.main"}}/>
                                    <span className={"navbar-link-black"}>Billing</span>
                                </MenuItem>
                            }

                            {props.userInformation &&
                                <MenuItem onClick={logout}>
                                    <LogoutIcon sx={{color: "primary.main"}}/>
                                    <span className={"navbar-link-black"}>Logout</span>
                                </MenuItem>
                            }
                        </Menu>
                    </Box>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={(event) => {toggleDrawer(event, true)}}
                        sx={{ mr: 2, display: { xs: "block", sm:"none"}}}>
                        <MenuIcon />
                    </IconButton>
                </Box>


                <Drawer
                    anchor="right"
                    variant="temporary"
                    open={state.openSideMenu}
                    onClose={(event) => {toggleDrawer(event, false)}}
                >

                    <Box
                        sx = {{
                            p: 2,
                            height: 1,
                            backgroundColor: "white"
                        }}
                    >
                        <IconButton sx={{mb: 2}} onClick={(event) => {
                            toggleDrawer(event, false)
                        }}>
                            <CloseIcon  />
                        </IconButton>

                        <Divider sx={{mb: 2}} />

                        <Box sx={{mb: 2}}>
                            {props.userInformation && props.userInformation.plan !== "None" &&
                                <ListItemButton onClick={() => document.location.href = "/dashboard"}>
                                    <ListItemIcon>
                                        <DashboardIcon sx={{color: "primary.main"}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Dashboard"/>
                                </ListItemButton>
                            }

                            <ListItemButton onClick={() => document.location.href="/userGuide"}>
                                <ListItemIcon>
                                    <InfoIcon sx={{color: "primary.main"}}/>
                                </ListItemIcon>
                                <ListItemText primary="User Guide" />
                            </ListItemButton>

                            <ListItemButton onClick={() => document.location.href="/pricing"}>
                                <ListItemIcon>
                                    <AttachMoneyIcon sx={{color: "primary.main"}}/>
                                </ListItemIcon >
                                <ListItemText primary="Pricing" />
                            </ListItemButton>

                            <ListItemButton onClick={() => document.location.href="/contact"}>
                                <ListItemIcon>
                                    <EmailIcon sx={{color: "primary.main"}}/>
                                </ListItemIcon>
                                <ListItemText primary="Contact Us" />
                            </ListItemButton>

                            {!props.userInformation &&
                                <ListItemButton onClick={() => document.location.href = "/login"}>
                                    <ListItemIcon>
                                        <LoginIcon sx={{color: "primary.main"}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Login"/>
                                </ListItemButton>
                            }

                            {!props.userInformation &&
                                <ListItemButton onClick={() => document.location.href="/createAccount"}>
                                    <ListItemIcon>
                                        <AppRegistrationIcon sx={{color: "primary.main"}} />
                                    </ListItemIcon>
                                    <ListItemText primary="Create Account" />
                                </ListItemButton>
                            }

                            {props.userInformation &&
                                <ListItemButton onClick={() => document.location.href = "/profile"}>
                                    <ListItemIcon>
                                        <AccountBoxIcon sx={{color: "primary.main"}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="My Account"/>
                                </ListItemButton>
                            }

                            {props.userInformation &&
                                <ListItemButton onClick={() => document.location.href = "/billing"}>
                                    <ListItemIcon>
                                        <AttachMoneyIcon sx={{color: "primary.main"}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Billing"/>
                                </ListItemButton>
                            }

                            {props.userInformation &&
                                <ListItemButton onClick={() => logout()}>
                                    <ListItemIcon>
                                        <LogoutIcon sx={{color: "primary.main"}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Logout"/>
                                </ListItemButton>
                            }
                        </Box>
                    </Box>
                </Drawer>
            </Toolbar>
        </AppBar>
    );
};

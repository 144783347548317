import React, {useState} from "react";
import {Button, IconButton, InputAdornment, TextField} from "@mui/material";
import "../styles/CreateAccount.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert";
import {Form} from "react-router-dom";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {makeStyles} from "@material-ui/core/styles";

export default function CreateAccount() {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    function createAccount() {
        const formData = {
            firstName:document.getElementById("firstName").value,
            lastName:document.getElementById("lastName").value,
            email:document.getElementById("email").value,
            password:document.getElementById("password").value,
            company:document.getElementById("company").value
        };

        const isPasswordValid = formData.password.length >= 8;

        if(!isPasswordValid) {
            swal({title:"Oops!", text:"Password must be at least 8 characters!", icon:"error"});
            setIsPasswordValid(false);
            return;
        }

        setIsPasswordValid(true);

        Ajax.request({
            url:"/QrBom/checkEmailAvailable",
            jsonData:{email:formData.email},
            success:function(reply) {
                if(reply.data) {
                    Ajax.request({
                        url:"/QrBom/createAccount",
                        jsonData: formData,
                        success:function() {
                            document.location.href = "/verifyEmail";
                        }
                    });
                } else {
                    swal({title:"Oops!", text:"Email Taken!", icon:"error", timer: 3000});
                }
            }
        });
    }

    function loginMicrosoft() {
        document.location.href = "/AuthAzure/login"
    }

    const useStyles = makeStyles((theme) => ({
        textField: {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 230
            }
        },
        password: {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 230
            },
        },
        passwordError: {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 230
            },
            '& .MuiOutlinedInput-root': {
                borderColor: 'red',
            },
            '& .MuiInputLabel-root': {
                color: 'red',
            },
        }
    }));

    const classes = useStyles();

    return (
        <div className={"createAccount"}>
            <h1>Create Account</h1>
            <Button sx={{"margin-bottom":"1rem"}} className={"loginButton"} onClick={() => document.location.href="/login"}><span>Already Have an Account? <span className={"login-text"}><u>Login</u></span></span></Button>
            <Form onSubmit={createAccount} className={classes.textField}>
                <TextField
                    id="firstName"
                    label="First Name"
                    variant="standard"
                    required={true}
                    className={classes.textField}
                >
                </TextField>
                <TextField
                    id="lastName"
                    label="Last Name"
                    variant="standard"
                    required={true}
                    className={classes.textField}
                >
                </TextField>
                <TextField
                    id="email"
                    label="Email"
                    variant="standard"
                    type={"email"}
                    required={true}
                    className={classes.textField}
                >
                </TextField>
                <TextField
                    id="password"
                    label="Password"
                    variant="standard"
                    required={true}
                    type={showPassword ? "text" : "password"}
                    className={!isPasswordValid ? classes.passwordError : classes.password}
                    InputProps={{ // <-- This is where the toggle button is added.
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                tabIndex={-1}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                >
                </TextField>
                <TextField
                    id="company"
                    label="Company Name"
                    variant="standard"
                    required={true}
                    className={classes.textField}
                >
                </TextField>
                <Button type={"submit"} sx={{"margin-top":"1rem"}} className={"createAccountButton"}><FontAwesomeIcon className={"login-icon"} icon={faPlus}/>Create Account</Button>
            </Form>
            {/*<Button sx={{"margin-top":"1rem"}} onClick={loginMicrosoft}><img className={"login-icon"} src={require("../images/microsoft.png")} width={16} height={16}  alt={"Microsoft"}/>Login With Microsoft</Button>*/}
        </div>
    );
}
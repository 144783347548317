import React, {useEffect} from "react";
import Header from "./components/Header";
import Home from "./components/Home";
import Ajax from "./inc/js/Ajax";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ContactUs from "./components/ContactUs";
import CreateAccount from "./components/CreateAccount";
import Login from "./components/Login";
import Pricing from "./components/Pricing";
import Profile from "./components/Profile";
import Dashboard from "./components/Dashboard";
import Lookup from "./components/Lookup";
import PrivacyPolicy from "./components/PrivacyPolicy";
import VerifyEmail from "./components/VerifyEmail";
import Error404 from "./components/Error404";
import Billing from "./components/Billing";
import ConfirmSubscriptionPage from "./components/ConfirmSubscription";
import ConfirmSubscription from "./components/ConfirmSubscription";
import ResetPassword from "./components/ResetPassword";
import ResetPasswordConfirm from "./components/ResetPasswordConfirm";
import UserGuide from "./components/UserGuide";

export default function App() {
    const [userInformation, setUserInformation] = React.useState(false);

    useEffect(() => {
        Ajax.request({
            url:"/QrBom/checkIfLoggedIn",
            method:"GET",
            success:function(reply) {
                if(reply.data.loggedIn) {
                    setUserInformation(reply.data.userInformation);
                }
            }
        });
    }, []);

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home userInformation = {userInformation} />,
            errorElement: <Error404 />,
        },
        {
            path: "/contact",
            element: <ContactUs />,
        },
        {
            path: "/createAccount",
            element: <CreateAccount />,
        },
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/pricing",
            element: <Pricing />,
        },
        {
            path: "/profile",
            element: <Profile userInformation = {userInformation} />,
        },
        {
            path: "/dashboard",
            element: <Dashboard userInformation = {userInformation} />,
        },
        {
            path: "/lookup",
            element: <Lookup />,
        },
        {
            path: "/privacyPolicy",
            element: <PrivacyPolicy />,
        },
        {
            path: "/verifyEmail",
            element: <VerifyEmail />,
        },
        {
            path: "/billing",
            element: <Billing />,
        },
        {
            path: "/confirmSubscription",
            element: <ConfirmSubscription />,
        },
        {
            path: "/resetPassword",
            element: <ResetPassword />,
        },
        {
            path: "/resetPasswordConfirm",
            element: <ResetPasswordConfirm />,
        },
        {
            path: "/userGuide",
            element: <UserGuide userInformation = {userInformation}/>,
        }
    ]);

    return (
        <div className={"app"}>
            <Header userInformation = {userInformation}/>
            <div id="loading">
                <img id="loading-image" src={require("./images/loading.gif")} alt="Loading..." />
            </div>
            <RouterProvider router={router} />
        </div>
    );
}
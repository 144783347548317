import React, {useEffect, useState} from "react";
import Ajax from "../inc/js/Ajax";
import {Box} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import GetAppIcon from '@mui/icons-material/GetApp';

export default function Billing() {
    const [invoices, setInvoices] = useState([]);
    const columns = [
        {field:"invoiceId", headerName:"Invoice ID"},
        {field:"invoiceNumber", headerName:"Invoice", width: 175},
        {field:"date", headerName:"Date", width: 100},
        {field:"plan", headerName:"Plan", width: 200},
        {field:"amount", headerName:"Amount", width: 100},
        {field:"pdf", headerName:"PDF", width: 100, renderCell: (params) => (<a href={params.value} download><GetAppIcon /></a>)}
    ];

    useEffect(() => {
        readInvoices();
    }, []);

    function readInvoices() {
        Ajax.request({
            url:"/QrBom/readInvoices",
            success:function(reply) {
                setInvoices(reply.data);
            }
        });
    }

    return (
        <Box className={"invoices"} sx={{padding:"1rem"}}>
            <Box height={800} sx={{width:"100%"}}>
                <DataGrid columns={columns} rows={invoices}
                          initialState={{
                              columns: {
                                  columnVisibilityModel: {
                                      invoiceId: false
                                  },
                              }
                          }}
                />
            </Box>
        </Box>
    );
};
import React from "react";
import "../styles/ContactUs.css"
import {Button, TextField} from "@mui/material";
import InputMask from 'react-input-mask';
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert";
import {makeStyles} from "@material-ui/core/styles";

export default function ContactUs() {

    function submitForm() {
        const form = {
            name:document.getElementById("name").value,
            email:document.getElementById("email").value,
            phone:document.getElementById("phone").value,
            companyName:document.getElementById("companyName").value,
            inquiry:document.getElementById("inquiry").value,
        };

        Ajax.request({
            url:"/QrBom/contactUs",
            jsonData:form,
            success:function() {
                document.getElementById("name").value = "";
                document.getElementById("email").value = "";
                document.getElementById("phone").value = "";
                document.getElementById("companyName").value = "";
                document.getElementById("inquiry").value = "";
                swal({title:"Success!", text:"Contact Request Sent!", icon:"success", timer: 3000});
            }
        });
    }

    const useStyles = makeStyles((theme) => ({
        textField: {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 230
            }
        }
    }));

    const classes = useStyles();

    return (
        <div className={"contactUs"}>
            <h1>Contact Us</h1>
            <form className={"form"} onSubmit={submitForm}>
                <TextField
                    id="name"
                    className={classes.textField}
                    label="Name"
                    variant="standard"
                    sx={{mb: 1}}
                    required={true}
                    fullWidth={window.innerWidth < 760}
                ></TextField>
                <TextField
                    id="email"
                    className={classes.textField}
                    label="Email"
                    variant="standard"
                    type={"email"}
                    sx={{mb: 1}}
                    required={true}
                    fullWidth={window.innerWidth < 760}
                ></TextField>
                <InputMask
                    mask="(999) 999-9999"
                >
                    {(inputProps) => (
                        <TextField
                            {...inputProps}
                            id="phone"
                            className={classes.textField}
                            label="Phone #"
                            variant="standard"
                            sx={{mb: 1}}
                            required={true}
                            fullWidth={window.innerWidth < 760}
                        />
                    )}
                </InputMask>
                <TextField
                    id="companyName"
                    className={classes.textField}
                    label="Company Name"
                    variant="standard"
                    sx={{mb: 1}}
                    required={true}
                    fullWidth={window.innerWidth < 760}
                ></TextField>
                <TextField
                    id="inquiry"
                    className={classes.textField}
                    label="Inquiry"
                    variant="standard"
                    sx={{mb: 1}}
                    multiline={true}
                    required={true}
                    fullWidth={true}
                ></TextField>
                <Button type={"submit"} sx={{color:"white", backgroundColor:"#1976D2"}}>Submit</Button>
            </form>
        </div>
    );
}
import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/ConfirmSubscription.css";

export default function ConfirmSubscription() {
    return (
        <div className="confirm-subscription-page">
            <h1>Thank you for subscribing!</h1>
            <p>To access your dashboard and start using your subscription, please click the link below:</p>
            <Link to="/dashboard">Access Your Dashboard</Link>
            <Link to="/userGuide">Read the User Guide</Link>
            <p>We appreciate your support and look forward to providing you with the best experience possible.</p>
        </div>
    );
};
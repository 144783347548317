import React, {useEffect, useState} from "react";
import Ajax from "../inc/js/Ajax";
import {Autocomplete, Box, Button, TextField} from "@mui/material";
import {DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import DocForm from "../inc/js/DocForm";
import DeleteIcon from '@mui/icons-material/Delete';
import {confirmAlert} from "react-confirm-alert";
import swal from "sweetalert";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Papa from 'papaparse';

let selectedAssemblyId = null;
export default function Assemblies(props) {
    const [assemblies, setAssemblies] = useState([]);

    const [components, setComponents] = useState([]);

    const [comboData, setComboData] = useState({components:[]});

    const [docFormData, setDocFormData] = useState({form:{}, hash:""});

    let initialAssemblyColumns = [
        {field:"assemblyId", headerName:"Assembly ID", hide:true},
        {field:"assembly", headerName:"Assembly", width: 200},
        {field:"description", headerName:"Description", width: 300},
        {field:"externalId", headerName:"External ID", width: 200},
        {field:"notes", headerName:"Notes", width: 300},
    ];

    if(localStorage.getItem("assemblyColumns")) {
        let savedColumns = JSON.parse(localStorage.getItem("assemblyColumns"));
        for(let column in initialAssemblyColumns) {
            initialAssemblyColumns[column].hide = savedColumns[column].hide;
        }
    }

    const [assemblyColumns, setAssemblyColumns] = useState(initialAssemblyColumns);

    let initialAssemblyComponentColumns = [
        {field:"id", headerName:"Link ID", hide:true},
        {
            field:"action",
            headerName: "Action",
            sortable:false,
            renderCell: (params) => {
                return <Button onClick={() => {deleteComponentFromAssembly(params.id)}}><DeleteIcon />Delete</Button>;
            }
        },
        {field:"component", headerName:"Component", width: 200},
        {field:"description", headerName:"Description", width: 300},
        {field:"externalId", headerName:"External ID", width: 200, hide:true},
        {field:"link", headerName:"Link", width: 200},
        {field:"notes", headerName:"Notes", width: 300}
    ];

    if(localStorage.getItem("assemblyComponentColumns")) {
        let savedColumns = JSON.parse(localStorage.getItem("assemblyComponentColumns"));
        for(let column in savedColumns) {
            initialAssemblyComponentColumns[column].hide = savedColumns[column].hide;
        }
    }

    const [assemblyComponentColumns, setAssemblyComponentColumns] = useState(initialAssemblyComponentColumns);

    useEffect(() => {
        readAssemblies();
    }, []);

    useEffect(() => {
        Ajax.request({
            url:"/QrBom/readDashboardComboData",
            success:function(reply) {
                setComboData({
                    components:reply.data.components
                });
            }
        });
    }, []);

    function readAssemblies() {
        Ajax.request({
            url:"/QrBom/readAssemblies",
            success:function(reply) {
                setAssemblies(reply.data);
            }
        });
    }

    function selectionChange(record) {
        readAssembly(record.row.assemblyId);
    }

    function readAssembly(assemblyId) {
        Ajax.request({
            url:"/QrBom/readAssembly",
            jsonData:{assemblyId:assemblyId},
            success:function(reply) {
                reply.data.docFormRecordId = assemblyId;
                selectedAssemblyId = assemblyId;
                setDocFormData({form:reply.data, hash:(Math.random() + 1).toString(36).substring(7)});
                readComponentsForAssembly(assemblyId);
            }
        });
    }

    function readComponentsForAssembly(assemblyId) {
        Ajax.request({
            url:"/QrBom/readComponentsByAssembly",
            jsonData:{assemblyId:assemblyId},
            success:function(reply) {
                setComponents(reply.data);
            }
        });
    }

    function addComponentToAssembly(event, record) {
        if(selectedAssemblyId === null) {
            swal({title:"Oops!", text:"No Assembly Loaded!", icon:"error", timer: 3000});
        }

        Ajax.request({
            url:"/QrBom/addComponentToAssembly",
            jsonData:{
                assemblyId:selectedAssemblyId,
                componentId:record.id
            },
            success:function(reply) {
                readComponentsForAssembly(selectedAssemblyId);
            }
        });

        document.getElementById("componentToAdd").value = "";
    }

    function deleteComponentFromAssembly(linkId) {
        const options = {
            title: 'Delete',
            message: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        Ajax.request({
                            url:"/QrBom/deleteComponentFromAssembly",
                            jsonData:{linkId:linkId},
                            success:function(reply) {
                                readComponentsForAssembly(selectedAssemblyId);
                            }
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            overlayClassName: "overlay-custom-class-name"
        };

        confirmAlert(options);
    }

    const [open, setOpen] = useState(false);

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    function uploadAssemblies() {
        document.getElementById('assembly-file-input').click()
    }

    function handleFileUpload(event) {
        const uploadedFile = event.target.files[0];
        event.target.value = null;
        if(!uploadedFile) {
            return;
        }

        const reader = new FileReader();

        reader.onload = () => {
            const fileContent = reader.result;
            const parsedData = Papa.parse(fileContent, { header: true});

            if(!parsedData || !parsedData.data) {
                swal({title:"Oops!", text:"Invalid Assembly CSV", icon:"error", timer: 3000});
                return;
            }

            if(parsedData.data.length === 0) {
                swal({title:"Oops!", text:"Assembly CSV has zero rows!", icon:"error", timer: 3000});
                return;
            }

            Ajax.request({
                url:"/QrBom/importAssembliesFromCsv",
                jsonData:{assemblies:parsedData.data},
                success:function(reply) {
                    handleCloseDialog();
                    swal({title:"Success!", text:reply.data.newAssemblies + " Assemblies Imported\r\n" + reply.data.updatedAssemblies + " Assemblies Updated", icon:"success", timer: 30000});
                    readAssemblies();
                }
            });
        };

        reader.readAsText(uploadedFile);
    }

    const [openImportLinks, setOpenImportLinks] = useState(false);

    const handleOpenImportLinksDialog = () => {
        setOpenImportLinks(true);
    };

    const handleCloseImportLinksDialog = () => {
        setOpenImportLinks(false);
    };

    function uploadAssemblyComponentLinks() {
        document.getElementById('assembly-component-link-file-input').click()
    }

    function handleAssemblyComponentLinkFileUpload(event) {
        const uploadedFile = event.target.files[0];
        event.target.value = null;
        if(!uploadedFile) {
            return;
        }

        const reader = new FileReader();

        reader.onload = () => {
            const fileContent = reader.result;
            const parsedData = Papa.parse(fileContent, { header: true});

            if(!parsedData || !parsedData.data) {
                swal({title:"Oops!", text:"Invalid Assembly-Component Link CSV", icon:"error", timer: 3000});
                return;
            }

            if(parsedData.data.length === 0) {
                swal({title:"Oops!", text:"Assembly-Component Link CSV has zero rows!", icon:"error", timer: 3000});
                return;
            }

            Ajax.request({
                url:"/QrBom/importAssemblyComponentLinksFromCsv",
                jsonData:{assemblyComponentLinks:parsedData.data},
                success:function(reply) {
                    handleCloseDialog();
                    swal({title:"Success!", text:reply.data.newAssemblyComponentLinks + " Components Linked to Assemblies", icon:"success", timer: 30000});
                    if(selectedAssemblyId !== null) {
                        readComponentsForAssembly(selectedAssemblyId);
                    }
                }
            });
        };

        reader.readAsText(uploadedFile);
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{fileName:"QRBOM Assemblies"}}/>
                <GridToolbarColumnsButton />
                {props.userInformation && (["Standard", "Enterprise"].includes(props.userInformation.plan)) && <Button onClick={handleOpenDialog}><UploadFileIcon />Import</Button>}
            </GridToolbarContainer>
        );
    }

    function CustomComponentToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{fileName:"QRBOM Assembly Components"}}/>
                <GridToolbarColumnsButton />
                {props.userInformation && (["Standard", "Enterprise"].includes(props.userInformation.plan)) && <Button onClick={handleOpenImportLinksDialog}><UploadFileIcon />Import</Button>}
            </GridToolbarContainer>
        );
    }

    function handleAssemblyColumnVisibilityChange(field) {
        const updatedColumns = assemblyColumns.map((col) => {
                return col.field === field.field ? {...col, hide: !field.isVisible} : col
            }
        );

        setAssemblyColumns(updatedColumns);

        localStorage.setItem("assemblyColumns", JSON.stringify(updatedColumns));
    }

    function handleAssemblyComponentColumnVisibilityChange(field) {
        const updatedColumns = assemblyComponentColumns.map((col) => {
                return col.field === field.field ? {...col, hide: !field.isVisible} : col
            }
        );

        setAssemblyComponentColumns(updatedColumns);

        localStorage.setItem("assemblyComponentColumns", JSON.stringify(updatedColumns));
    }

    return (
        <>
            <input
                id="assembly-file-input"
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
            />
            <input
                id="assembly-component-link-file-input"
                type="file"
                accept=".csv"
                onChange={handleAssemblyComponentLinkFileUpload}
                style={{ display: 'none' }}
            />
            <div>
                <Dialog open={open} onClose={handleCloseDialog}>
                    <DialogTitle>Import CSV Format</DialogTitle>
                    <DialogContent>
                        <img src={require("../images/AssemblyImportExample.png")} alt="Image description" />
                    </DialogContent>
                    <DialogActions sx={{ position: 'absolute', top: 0, right: 0 }}>
                        <Box sx={{ cursor: 'pointer' }} onClick={handleCloseDialog}>
                            <CloseIcon sx={{ color: 'red' }} />
                        </Box>
                    </DialogActions>
                    <Button onClick={uploadAssemblies}><UploadFileIcon />Import</Button>
                </Dialog>
            </div>
            <div>
                <Dialog open={openImportLinks} onClose={handleCloseImportLinksDialog}>
                    <DialogTitle>Import CSV Format</DialogTitle>
                    <DialogContent>
                        <img src={require("../images/AssemblyComponentLinkImportExample.png")} alt="Image description" />
                    </DialogContent>
                    <DialogActions sx={{ position: 'absolute', top: 0, right: 0 }}>
                        <Box sx={{ cursor: 'pointer' }} onClick={handleCloseImportLinksDialog}>
                            <CloseIcon sx={{ color: 'red' }} />
                        </Box>
                    </DialogActions>
                    <Button onClick={uploadAssemblyComponentLinks}><UploadFileIcon />Import</Button>
                </Dialog>
            </div>
            <Box className={"gridContainer"} width={1200} height={400}>
                <Box className={"gridTitle"}>Assemblies</Box>
                <DataGrid columns={assemblyColumns} rows={assemblies} onRowClick={selectionChange}
                      onColumnVisibilityChange={handleAssemblyColumnVisibilityChange}
                      components={{
                          Toolbar: CustomToolbar,
                      }}
                />
            </Box>
            <Box width={600} sx={{mt:"1rem", display:"flex", flexDirection:"column"}}>
                <DocForm
                    form = {
                        <Box sx={{p:"1rem", display:"flex", flexDirection:"column"}}>
                            <TextField
                                id="assembly"
                                className="textField"
                                label="Assembly"
                                variant="standard"
                                value=""
                            ></TextField>
                            <TextField
                                id="description"
                                className="textField"
                                label="Description"
                                variant="standard"
                                value=""
                            ></TextField>
                            <TextField
                                id="externalId"
                                className="textField"
                                label="External ID"
                                variant="standard"
                                value=""
                            ></TextField>
                            <TextField
                                id="link"
                                className="textField"
                                label="Link"
                                variant="standard"
                                value=""
                            ></TextField>
                            <TextField
                                id="notes"
                                className="textField"
                                label="Notes"
                                variant="standard"
                                value=""
                            ></TextField>
                        </Box>
                    }

                    docFormData={docFormData}

                    createFunction = {(form) => {
                        Ajax.request({
                            url:"/QrBom/createAssembly",
                            jsonData:form,
                            success:function(reply) {
                                readAssemblies();
                                readAssembly(reply.data);
                            }
                        });
                    }}

                    updateFunction = {(form, assemblyId) => {
                        form.assemblyId = assemblyId;
                        Ajax.request({
                            url:"/QrBom/updateAssembly",
                            jsonData:form,
                            success:function(reply) {
                                readAssemblies();
                                readAssembly(assemblyId);
                            }
                        });
                    }}

                    deleteFunction = {(assemblyId) => {
                        Ajax.request({
                            url:"/QrBom/deleteAssembly",
                            jsonData:{assemblyId:assemblyId},
                            success:function(reply) {
                                readAssemblies();
                                setDocFormData({form:{}, hash:""});
                                selectedAssemblyId = null;
                                setComponents([]);
                            }
                        });
                    }}
                />
            </Box>
            <Autocomplete
                disablePortal
                id="componentToAdd"
                options={comboData.components}
                sx={{ mt:"2rem", width: "18.75rem" }}
                autoHighlight={true}
                blurOnSelect={true}
                value={null}
                onChange={addComponentToAssembly}
                renderInput={(params) => <TextField {...params} label="Add Component" />}
            />
            <Box className={"gridContainer"} width={1200} height={400} sx={{mt:"1rem"}}>
                <Box className={"gridTitle"}>Components</Box>
                <DataGrid columns={assemblyComponentColumns} rows={components}
                      onColumnVisibilityChange={handleAssemblyComponentColumnVisibilityChange}
                      components={{
                          Toolbar:CustomComponentToolbar
                      }}
                />
            </Box>
        </>
    );
}
import React, {useEffect, useState} from 'react';
import {Container, TextField, Button, InputAdornment, IconButton} from '@mui/material';
import Ajax from "../inc/js/Ajax";
import swal from "sweetalert";
import {makeStyles} from "@material-ui/core/styles";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export default function ResetPasswordConfirm() {
    const [newPassword, setNewPassword] = useState('');
    const [code, setCode] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        const code = params.code;

        Ajax.request({
            url:"/QrBom/validatePasswordResetCode",
            jsonData:{code:code},
            success:function(reply) {
                setCode(code);
            }
        })
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const isPasswordValid = newPassword.length >= 8;

        if(!isPasswordValid) {
            swal({title:"Oops!", text:"Password must be at least 8 characters!", icon:"error"});
            setIsPasswordValid(false);
            return;
        }

        setIsPasswordValid(true);

        Ajax.request({
            url:"/QrBom/resetPassword",
            jsonData:{code:code, newPassword:newPassword},
            success:function() {
                document.location.href = "/login";
            }
        });
    };

    const useStyles = makeStyles((theme) => ({
        password: {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 230
            },
        },
        passwordError: {
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 230
            },
            '& .MuiOutlinedInput-root': {
                borderColor: 'red',
            },
            '& .MuiInputLabel-root': {
                color: 'red',
            },
        }
    }));

    const classes = useStyles();


    return (
        <div>
            {code && <Container maxWidth="sm">
                <h1>Reset Password</h1>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="New Password"
                        type={showPassword ? "text" : "password"}
                        className={!isPasswordValid ? classes.passwordError : classes.password}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required={true}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        tabIndex={-1}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                    >
                        Update Password
                    </Button>
                </form>
            </Container>}
        </div>
    );
}
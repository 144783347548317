import React, {useEffect, useState} from "react";
import "../styles/Pricing.css"
import PriceBubble from "./PriceBubble";
import Ajax from "../inc/js/Ajax";

export default function Pricing(props) {
    const [prices, setPrices] = useState([]);

    useEffect(() => {
        Ajax.request({
            url:"/QrBom/readPrices",
            success:function(reply) {
                setPrices(reply.data);
            }
        });
    }, []);

    return (
        <div className={"pricing"}>
            <h1>Pricing</h1>
            <span className={"pricingTiers"}>
                {prices.map((price, i) => <PriceBubble {...price} />)}
            </span>
        </div>
    );
}
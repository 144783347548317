import React from "react";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import "../css/DocForm.css";
import {Button} from "@mui/material";

export default function DocForm(props) {
    const [docForm, updateDocForm] = React.useState({
        docFormState: "empty",
        docFormPreviousState: null,
        docFormRecordId:null,
        docFormLoadHash:null,
        docFormFields: {

        }
    });

    const validComponentTypes = ["textField"];

    let form = null;

    if(document.getElementById("docFormCreateButton") && !props.hasOwnProperty("createFunction")) {
        document.getElementById("docFormCreateButton").style.display = "none";
    }

    function docFormCreate() {
        docFormSetState("new");
    }

    function docFormUpdate() {
        for(let i in docForm.docFormFields) {
            docForm.docFormFields[i].originalValue = docForm.docFormFields[i].value;
        }

        if(docForm.docFormState === "new") {
            props.createFunction(docFormGetAllFieldValues());
        } else if(docForm.docFormState === "edit") {
            props.updateFunction(docFormGetAllFieldValues(), docForm.docFormRecordId);
        }
    }

    function docFormCancel() {
        switch(docForm.docFormState) {
            case "new":
                switch(docForm.docFormPreviousState) {
                    case "empty":
                        docFormSetState("empty");
                        break;
                    case "view":
                        for(let i in docForm.docFormFields) {
                            docForm.docFormFields[i].value = docForm.docFormFields[i].originalValue;
                        }
                        docFormSetState("view");
                        break;
                }
                break;
            case "edit":
                for(let i in docForm.docFormFields) {
                    docForm.docFormFields[i].value = docForm.docFormFields[i].originalValue;
                }
                docFormSetState("view");
                break;
        }
    }

    function docFormDelete() {
        const options = {
            title: 'Delete',
            message: 'Are you sure?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        docFormSetState("empty")
                        props.deleteFunction(docForm.docFormRecordId);
                        docForm.docFormRecordId = null;
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            overlayClassName: "overlay-custom-class-name"
        };

        confirmAlert(options);
    }

    function docFormSetState(docFormNewState) {
        if(docForm.docFormState === docFormNewState) {
            console.error("Already in state " + docFormNewState);
            return;
        }

        docForm.docFormPreviousState = docForm.docFormState;
        docForm.docFormState = docFormNewState;

        switch(docFormNewState) {
            case "empty":
                document.getElementById("docFormCreateButton").style.display = "inline";
                document.getElementById("docFormUpdateButton").style.display = "none";
                document.getElementById("docFormCancelButton").style.display = "none";
                document.getElementById("docFormDeleteButton").style.display = "none";

                for(let i in docForm.docFormFields) {
                    docForm.docFormFields[i].disabled = true;
                    docForm.docFormFields[i].originalValue = "";
                    docForm.docFormFields[i].value = "";
                }
                break;
            case "view":
                document.getElementById("docFormCreateButton").style.display = "inline";
                document.getElementById("docFormUpdateButton").style.display = "none";
                document.getElementById("docFormCancelButton").style.display = "none";
                document.getElementById("docFormDeleteButton").style.display = "inline";
                break;
            case "edit":
                document.getElementById("docFormCreateButton").style.display = "none";
                document.getElementById("docFormUpdateButton").style.display = "inline";
                document.getElementById("docFormCancelButton").style.display = "inline";
                document.getElementById("docFormDeleteButton").style.display = "none";
                break;
            case "new":
                document.getElementById("docFormCreateButton").style.display = "none";
                document.getElementById("docFormUpdateButton").style.display = "inline";
                document.getElementById("docFormCancelButton").style.display = "inline";
                document.getElementById("docFormDeleteButton").style.display = "none";

                for(let i in docForm.docFormFields) {
                    docForm.docFormFields[i].disabled = false;
                    docForm.docFormFields[i].value = "";
                }
                break;
            default:
                console.error("Invalid State: " + docFormNewState);
        }

        updateDocForm(Object.assign({},docForm));
    }

    function changeField(event) {
        docForm.docFormFields[event.target.id].value = event.target.value;
        let changeDetected = false;
        for(let i in docForm.docFormFields) {
            if(docForm.docFormState === "new" || String(docForm.docFormFields[i].originalValue) !== String(docForm.docFormFields[i].value)) {
                changeDetected = true;
                break;
            }
        }

        if(!changeDetected){
            docFormSetState("view");
        } else if(docForm.docFormState === "view") {
            docFormSetState("edit");
        } else {
            updateDocForm(Object.assign({},docForm));
        }
    }

    function docFormLoadFormData(formData) {
        if(formData.hash === docForm.docFormLoadHash) {
            return;
        }

        docForm.docFormLoadHash = formData.hash;

        for(let i in docForm.docFormFields) {
            docForm.docFormFields[i].disabled = false;
            docForm.docFormFields[i].originalValue = formData.form[i];
            docForm.docFormFields[i].value = formData.form[i];
        }

        docForm.docFormRecordId = formData.form.docFormRecordId;

        if(docForm.docFormState === "view") {
            updateDocForm(Object.assign({},docForm));
        } else {
            docFormSetState("view");
        }
    }

    function docFormGetAllFieldValues() {
        let form = {};
        for(let field in docForm.docFormFields) {
            form[field] = docForm.docFormFields[field].value;
        }

        return form;
    }

    let docFormFields = {};

    for(let component of props.form.props.children) {
        if(!validComponentTypes.includes(component.props.className)) {
            continue;
        }

        if(docForm.docFormFields.hasOwnProperty(component.props.id)) {
            continue;
        }

        docFormFields[component.props.id] = {originalValue:"", value:"", disabled:true}
    }

    if(Object.keys(docFormFields).length > 0) {
        updateDocForm({...docForm, ...{docFormFields:docFormFields}});
    }

    let components = [];
    let key = 1;
    if(Object.keys(docForm.docFormFields).length > 0) {
        for(let component of props.form.props.children) {
            if(!validComponentTypes.includes(component.props.className)) {
                continue;
            }

            if(component.props.hasOwnProperty("onChange")) {
                continue;
            }

            components.push( React.cloneElement(component, {
                key:key,
                value: docForm.docFormFields[component.props.id].value,
                disabled: docForm.docFormFields[component.props.id].disabled,
                onChange: changeField,
                style: (docForm.docFormState === "edit") && String(docForm.docFormFields[component.props.id].value) !== String(docForm.docFormFields[component.props.id].originalValue) ? {background:"#FFFF8F"} : {}
            }));

            key++;
        }

        form = React.cloneElement(props.form, {children:components});
    }

    if(Object.keys(props.docFormData.form).length > 0) {
        docFormLoadFormData(props.docFormData);
    }

    return (
        <div className="docForm">
            <div className="docFormToolbar">
                <Button id="docFormCreateButton" onClick={() => docFormCreate()}><AddIcon /> New</Button>
                <Button id="docFormUpdateButton" onClick={() => docFormUpdate()}><SaveIcon /> Save</Button>
                <Button id="docFormCancelButton" onClick={() => docFormCancel()}><CancelIcon /> Cancel</Button>
                <Button id="docFormDeleteButton" onClick={() => docFormDelete()}><DeleteIcon /> Delete</Button>
                <span className="docFormState">{docForm.docFormState}</span>
            </div>
            {form}
        </div>
    );
};
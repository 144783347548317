import React, {useEffect, useState} from "react";
import Ajax from "../inc/js/Ajax";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import DocForm from "../inc/js/DocForm";
import Papa from "papaparse";
import swal from "sweetalert";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";

export default function Components(props) {
    const [components, setComponents] = useState([]);

    let initialColumnState = [
        {field:"componentId", headerName:"Component ID", hide:true},
        {field:"component", headerName:"Component", width: 200},
        {field:"description", headerName:"Description", width: 300},
        {field:"externalId", headerName:"External ID", width: 200},
        {field:"link", headerName:"Link", width: 200},
        {field:"notes", headerName:"Notes", width: 300}
    ];

    if(localStorage.getItem("componentColumns")) {
        let savedColumns = JSON.parse(localStorage.getItem("componentColumns"));
        for(let column in initialColumnState) {
            if(savedColumns.hasOwnProperty(column)) {
                initialColumnState[column].hide = savedColumns[column].hide;
            }
        }
    }

    const [columns, setColumns] = useState(initialColumnState)

    const [docFormData, setDocFormData] = useState({form:{}, hash:""});

    useEffect(() => {
        readComponents();
    }, []);

    function readComponents() {
        Ajax.request({
            url:"/QrBom/readComponents",
            success:function(reply) {
                setComponents(reply.data);
            }
        });
    }

    function selectionChange(record) {
        readComponent(record.row.componentId);
    }

    function readComponent(componentId) {
        Ajax.request({
            url:"/QrBom/readComponent",
            jsonData:{componentId:componentId},
            success:function(reply) {
                reply.data.docFormRecordId = componentId;
                setDocFormData({form:reply.data, hash:(Math.random() + 1).toString(36).substring(7)});
            }
        });
    }

    function uploadComponents() {
        document.getElementById('component-file-input').click()
    }

    function handleFileUpload(event) {
        const uploadedFile = event.target.files[0];
        event.target.value = null;
        if(!uploadedFile) {
            return;
        }

        const reader = new FileReader();

        reader.onload = () => {
            const fileContent = reader.result;
            const parsedData = Papa.parse(fileContent, { header: true});

            if(!parsedData || !parsedData.data) {
                swal({title:"Oops!", text:"Invalid Components CSV", icon:"error", timer: 3000});
                return;
            }

            if(parsedData.data.length === 0) {
                swal({title:"Oops!", text:"Components CSV has zero rows!", icon:"error", timer: 3000});
                return;
            }

            Ajax.request({
                url:"/QrBom/importComponentsFromCsv",
                jsonData:{components:parsedData.data},
                success:function(reply) {
                    handleCloseDialog();
                    swal({title:"Success!", text:reply.data.newComponents + " Components Imported\r\n" + reply.data.updatedComponents + " Components Updated", icon:"success", timer: 30000});
                    readComponents();
                }
            });
        };

        reader.readAsText(uploadedFile);
    }

    const [open, setOpen] = useState(false);

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{fileName:"QRBOM Components"}} />
                <GridToolbarColumnsButton />
                {props.userInformation && (["Standard", "Enterprise"].includes(props.userInformation.plan)) && <Button onClick={handleOpenDialog}><UploadFileIcon />Import</Button>}
            </GridToolbarContainer>
        );
    }

    const handleColumnVisibilityChange = (field, checked) => {
        const updatedColumns = columns.map((col) => {
                return col.field === field.field ? {...col, hide: !field.isVisible} : col
            }
        );

        setColumns(updatedColumns);

        localStorage.setItem("componentColumns", JSON.stringify(updatedColumns));
    };

    return (
        <Box>
            <input
                id="component-file-input"
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
            />
            <div>
                <Dialog open={open} onClose={handleCloseDialog}>
                    <DialogTitle>Import CSV Format</DialogTitle>
                    <DialogContent>
                        <img src={require("../images/ComponentImportExample.png")} alt="Image description" />
                    </DialogContent>
                    <DialogActions sx={{ position: 'absolute', top: 0, right: 0 }}>
                        <Box sx={{ cursor: 'pointer' }} onClick={handleCloseDialog}>
                            <CloseIcon sx={{ color: 'red' }} />
                        </Box>
                    </DialogActions>
                    <Button onClick={uploadComponents}><UploadFileIcon />Import</Button>
                </Dialog>
            </div>
            <Box className={"gridContainer"} width={1200} height={400}>
                <Box className={"gridTitle"}>Components</Box>
                <DataGrid columns={columns} rows={components} onRowClick={selectionChange}
                          onColumnVisibilityChange={handleColumnVisibilityChange}
                          components={{
                              Toolbar: CustomToolbar,
                          }}
                />
            </Box>
            <Box width={600} sx={{mt:"1rem", display:"flex", flexDirection:"column"}}>
                <DocForm
                    form = {
                        <Box sx={{p:"1rem", display:"flex", flexDirection:"column"}}>
                            <TextField
                                id="component"
                                className="textField"
                                label="Component"
                                variant="standard"
                                value=""
                            ></TextField>
                            <TextField
                                id="description"
                                className="textField"
                                label="Description"
                                variant="standard"
                                value=""
                            ></TextField>
                            <TextField
                                id="externalId"
                                className="textField"
                                label="External ID"
                                variant="standard"
                                value=""
                            ></TextField>
                            <TextField
                                id="link"
                                className="textField"
                                label="Link"
                                variant="standard"
                                value=""
                            ></TextField>
                            <TextField
                                id="notes"
                                className="textField"
                                label="Notes"
                                variant="standard"
                                value=""
                            ></TextField>
                        </Box>
                    }

                    docFormData={docFormData}

                    createFunction = {(form) => {
                        Ajax.request({
                            url:"/QrBom/createComponent",
                            jsonData:form,
                            success:function(reply) {
                                readComponents();
                                readComponent(reply.data);
                            }
                        });
                    }}

                    updateFunction = {(form, componentId) => {
                        form.componentId = componentId;
                        Ajax.request({
                            url:"/QrBom/updateComponent",
                            jsonData:form,
                            success:function(reply) {
                                readComponents();
                                readComponent(componentId);
                            }
                        });
                    }}

                    deleteFunction = {(componentId) => {
                        Ajax.request({
                            url:"/QrBom/deleteComponent",
                            jsonData:{componentId:componentId},
                            success:function(reply) {
                                readComponents();
                                setDocFormData({form:{}, hash:""});
                            }
                        });
                    }}
                />
            </Box>
        </Box>
    );
}
import React, {useEffect, useState} from "react";
import Ajax from "../inc/js/Ajax";
import {Box, TextField} from "@mui/material";
import {
    DataGrid,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport
} from "@mui/x-data-grid";
import DocForm from "../inc/js/DocForm";
import Typography from "@material-ui/core/Typography";

export default function Components(props) {
    const [qrCodes, setQrCodes] = useState([]);

    let initialColumnState = [
        {field:"qrCodeId", headerName:"QR Code ID", hide:true},
        {field:"lastScan", headerName:"Last Scan", width: 200},
        {
            field: 'code',
            headerName: 'Link',
            width: 50,
            renderCell: (params) => (
                <a href={"/lookup?code=" + params.value} target="_blank" rel="noopener noreferrer">
                    {"Link"}
                </a>
            ),
        },
        {field:"date", headerName:"QR Code Date", width: 200},
        {field:"serialNumber", headerName:"Serial", width: 200},
        {field:"assemblyName", headerName:"Assembly", width: 300},
        {field:"link", headerName:"Link", width: 200},
        {field:"notes", headerName:"Notes", width: 200}
    ];

    if(localStorage.getItem("pastQrCodeColumns")) {
        let savedColumns = JSON.parse(localStorage.getItem("pastQrCodeColumns"));
        for(let column in initialColumnState) {
            if(savedColumns.hasOwnProperty(column)) {
                initialColumnState[column].hide = savedColumns[column].hide;
            }
        }
    }

    const [columns, setColumns] = useState(initialColumnState)

    const [qrCodeScans, setQrCodeScans] = useState([]);

    let initialQrCodeScansColumnState = [
        {field:"scanDate", headerName:"Scan Date", width: 200},
        {field:"ip", headerName:"IP", width: 200}
    ];

    if(localStorage.getItem("pastQrCodeScansColumns")) {
        let savedColumns = JSON.parse(localStorage.getItem("pastQrCodeScansColumns"));
        for(let column in initialColumnState) {
            if(savedColumns.hasOwnProperty(column)) {
                initialQrCodeScansColumnState[column].hide = savedColumns[column].hide;
            }
        }
    }

    const [qrCodeScansColumns, setQrCodeScansColumns] = useState(initialQrCodeScansColumnState)

    const [docFormData, setDocFormData] = useState({form:{}, hash:""});

    useEffect(() => {
        readQrCodes();
    }, []);

    function readQrCodes() {
        Ajax.request({
            url:"/QrBom/readQrCodes",
            success:function(reply) {
                setQrCodes(reply.data);
            }
        });
    }

    function selectionChange(record) {
        readQrCode(record.row.qrCodeId);
    }

    function readQrCode(qrCodeId) {
        Ajax.request({
            url:"/QrBom/readQrCode",
            jsonData:{qrCodeId:qrCodeId},
            success:function(reply) {
                reply.data.docFormRecordId = qrCodeId;
                setDocFormData({form:reply.data, hash:(Math.random() + 1).toString(36).substring(7)});
                readQrCodeScans(qrCodeId);
            }
        });
    }

    function readQrCodeScans(qrCodeId) {
        Ajax.request({
            url:"/QrBom/readQrCodeScans",
            jsonData:{qrCodeId:qrCodeId},
            success:function(reply) {
                setQrCodeScans(reply.data);
            }
        });
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{fileName:"QRBOM QR Codes"}} />
                <GridToolbarColumnsButton />
            </GridToolbarContainer>
        );
    }

    function CustomScansToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{fileName:"QRBOM QR Code Scans"}} />
                <GridToolbarColumnsButton />
            </GridToolbarContainer>
        );
    }

    const handleColumnVisibilityChange = (field, checked) => {
        const updatedColumns = columns.map((col) => {
                return col.field === field.field ? {...col, hide: !field.isVisible} : col
            }
        );

        setColumns(updatedColumns);

        localStorage.setItem("pastQrCodeColumns", JSON.stringify(updatedColumns));
    };

    const handleScansColumnVisibilityChange = (field, checked) => {
        const updatedColumns = columns.map((col) => {
                return col.field === field.field ? {...col, hide: !field.isVisible} : col
            }
        );

        setQrCodeScansColumns(updatedColumns);

        localStorage.setItem("pastQrCodeScansColumns", JSON.stringify(updatedColumns));
    };

    return (
        <Box>
            <Box className={"gridContainer"} width={1200} height={400}>
                <Box className={"gridTitle"}>QR Codes</Box>
                <DataGrid columns={columns} rows={qrCodes} onRowClick={selectionChange}
                          onColumnVisibilityChange={handleColumnVisibilityChange}
                          components={{
                              Toolbar: CustomToolbar,
                          }}
                />
            </Box>
            <Box width={600} sx={{mt:"1rem", display:"flex", flexDirection:"column"}}>
                <DocForm
                    form = {
                        <Box sx={{p:"1rem", display:"flex", flexDirection:"column"}}>
                            <TextField
                                id="dateCreated"
                                className="textField"
                                label="Date Created"
                                variant="standard"
                                value=""
                            ></TextField>
                            <TextField
                                id="serialNumber"
                                className="textField"
                                label="Serial #"
                                variant="standard"
                                value=""
                            ></TextField>
                            <TextField
                                id="assemblyName"
                                className="textField"
                                label="Assembly"
                                variant="standard"
                                value=""
                            ></TextField>
                            <TextField
                                id="assemblyDescription"
                                className="textField"
                                label="Description"
                                variant="standard"
                                value=""
                            ></TextField>
                            <TextField
                                id="link"
                                className="textField"
                                label="Link"
                                variant="standard"
                                value=""
                            ></TextField>
                            <TextField
                                id="notes"
                                className="textField"
                                label="Notes"
                                variant="standard"
                                value=""
                            ></TextField>
                        </Box>
                    }

                    docFormData={docFormData}

                    updateFunction = {(form, qrCodeId) => {
                        form.qrCodeId = qrCodeId;
                        Ajax.request({
                            url:"/QrBom/updateQrCode",
                            jsonData:form,
                            success:function(reply) {
                                readQrCodes();
                                readQrCode(qrCodeId);
                            }
                        });
                    }}

                    deleteFunction = {(qrCodeId) => {
                        Ajax.request({
                            url:"/QrBom/deleteQrCode",
                            jsonData:{qrCodeId:qrCodeId},
                            success:function(reply) {
                                readQrCodes();
                                setDocFormData({form:{}, hash:""});
                            }
                        });
                    }}
                />
            </Box>
            <Box sx = {{mt:"1rem"}} width={600} height={400}>
                <Box className={"gridTitle"}>Scans</Box>
                <DataGrid title = {"Scans"} columns={qrCodeScansColumns} rows={qrCodeScans}
                          onColumnVisibilityChange={handleScansColumnVisibilityChange}
                          components={{
                              Toolbar: CustomScansToolbar,
                          }}
                />
            </Box>
        </Box>
    );
}